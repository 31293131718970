//TODO: GROW-1930 Clean-up BFCM

export interface RingCardData {
  title: string;
  src: string;
  mobileSrc: string;
  hrefShop: string;
  hrefExplore: string;
  location: string;
  dataCyExplore: string;
  dataCyShop: string;
  pdpAction: string;
  popAction: string;
}

const ringCardData: RingCardData[] = [
  {
    title: 'pdp_style_oura_ring_4',
    src: 'home/OR4-bfcm.jpg',
    mobileSrc: 'home/bfcm/OR4-ring-module.jpg',
    hrefShop: '/product/rings/oura-ring-4/silver/',
    hrefExplore: '/product/rings/',
    location: 'homepage or4 module',
    dataCyExplore: 'OR4Explore',
    dataCyShop: 'OR4Shop',
    pdpAction: 'go to or4 wizard',
    popAction: 'go to or4 pop',
  },
  {
    title: 'hero_title_variant_one',
    src: 'home/OR3-bfcm.jpg',
    mobileSrc: 'home/bfcm/OR3-ring-module.jpg',
    hrefShop: '/product/rings/oura-gen3/heritage/',
    hrefExplore: '/product/rings/oura-gen3/',
    dataCyExplore: 'OR3Explore',
    dataCyShop: 'OR3Shop',
    location: 'homepage gen3 module',
    pdpAction: 'go to gen3 wizard',
    popAction: 'go to gen3 pop',
  },
];
export default ringCardData;
