import { InverseHeader } from '@jouzen/ecom-components';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';

import { EventType } from '@/analytics/types';
import ArtDirectionImage from '@/app/components/Image/ArtDirectionImage';
import Motion from '@/app/components/Motion';
import SectionHeading from '@/app/components/SectionHeading';
import useBlackFriday2024Pricing from '@/app/hooks/useBlackFriday2024Pricing';

import HomeHero from '../HomeHero';
import HomeHeroShopChiclet from '../HomeHeroShopChiclet';
import HomeHeroWithChicletWrapper from '../HomeHeroWithChicletWrapper';

const BlackFriday2024Hero = (): JSX.Element => {
  const t = useTranslations();
  const path = usePathname();
  const { greatestDiscount, lowestPrice } = useBlackFriday2024Pricing();

  return (
    <HomeHeroWithChicletWrapper data-cy="bfcm24-hero">
      <InverseHeader enter margin="0px">
        <HomeHero
          heroContent={{
            button: {
              children: null,
              href: '/product/rings/oura-gen3',
              label: 'bfcm_2024_home_hero_cta',
              onClick: () => {
                void window.ouraAnalytics.track(EventType.CTAClicked, {
                  cta: 'shop_sale',
                  action: 'go_to_pdp',
                  location: 'home hero',
                  path,
                });
              },
            },
            copy: {
              value: t.rich('bfcm_2024_home_hero_copy', {
                greatestDiscount,
                lowestPrice,
              }),
            },
          }}
          overlayPosition="absolute"
          TitleSlot={
            <div className="mt-24 md:max-w-[540px] lg:mt-[33vh] maxlg:mx-auto">
              <SectionHeading
                content="bfcm_2024_home_hero_title"
                typographyProps={{
                  className: 'font-normal leading-tighter maxlg:text-center',
                  color: 'light',
                  Element: 'h1',
                  variant: 'h1',
                }}
              />
            </div>
          }
          TopSlot={
            <Motion
              className="relative z-10 h-full"
              animate={{ opacity: 1, scale: 1 }}
              initial={{ opacity: 0, scale: 1.1 }}
              transition={{ duration: 1.5, type: 'tween' }}
            >
              <ArtDirectionImage
                alt=""
                className="object-cover"
                fill
                priority
                srcSet={[
                  {
                    media: '(min-width: 1024px) and (orientation: landscape)',
                    src: 'blue-sky/home/BF_Oura_Packshot_XL.png?ar=3:2&fit=crop&crop=focalpoint&fp-x=0.1&fp-y=0.5&fp-z=1.25',
                  },
                  {
                    media: '(min-width: 1024px) and (orientation: portrait)',
                    src: 'blue-sky/home/BF_Oura_Packshot_XL.png?ar=4:5&fit=crop&crop=focalpoint&fp-x=0.39&fp-y=0.48&fp-z=1.1',
                  },
                  {
                    src: 'blue-sky/home/BF_Oura_Packshot_XL.png',
                  },
                ]}
                sizes="100vw"
              />
            </Motion>
          }
        />
      </InverseHeader>
      <HomeHeroShopChiclet />
    </HomeHeroWithChicletWrapper>
  );
};

export default BlackFriday2024Hero;
