import { ButtonV3, TypographyV3 } from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';

import { EventType } from '@/analytics/types';
import Image from '@/app/components/Image';
import Motion from '@/app/components/Motion';
import useBlackFriday2024Pricing from '@/app/hooks/useBlackFriday2024Pricing';

import ringCardData from './_data/ringCardData';

//TODO: GROW-1930 Clean-up BFCM
const HomeRingModules = () => {
  const t = useTranslations();
  const { lowestPrice, lowestPriceOr4 } = useBlackFriday2024Pricing();

  const handleClick = async ({
    cta,
    location,
    path,
    action,
  }: {
    cta: string;
    location: string;
    path: string;
    action: string;
  }): Promise<void> => {
    await window.ouraAnalytics.track(EventType.CTAClicked, {
      cta: cta,
      location: location,
      path: path,
      action: action,
    });
  };

  return (
    <section
      className="relative gap-6 px-5 pt-6 md:flex md:flex-row md:py-6"
      data-cy="home-ring-module"
    >
      {ringCardData.map(
        ({
          src,
          mobileSrc,
          title,
          hrefShop,
          hrefExplore,
          location,
          pdpAction,
          popAction,
          dataCyExplore,
          dataCyShop,
        }) => (
          <Motion
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, type: 'tween', delay: 0.25 }}
            key={title}
          >
            <div
              className="relative size-full items-end pb-6 md:flex md:pb-0"
              data-cy={title}
            >
              <Image
                width={3000}
                height={700}
                src={src}
                className="hidden md:flex md:aspect-[2048/1437] md:rounded-xl"
              />
              <Image
                width={3000}
                height={700}
                src={mobileSrc}
                className="relative aspect-[2048/1437] rounded-xl md:hidden"
              />
              <div className="flex w-full justify-between pt-4 md:absolute md:px-4 md:pb-4 md:pt-0 lg:px-6 lg:pb-6 xl:px-10 xl:pb-10">
                <div className="md:self-end">
                  <TypographyV3 className="text-2xl md:text-2xl md:font-extralight md:text-white lg:text-3xl xl:pb-[3px] xl:text-4xl xxl:text-5xl">
                    {t(title)}
                  </TypographyV3>
                  <div className="flex text-sandstone-500 md:text-white">
                    <TypographyV3
                      className="xl:text-x text-sm md:text-white lg:text-base"
                      weight={'bold'}
                    >
                      {t('from')}&nbsp;
                      {title === 'pdp_style_oura_ring_4'
                        ? lowestPriceOr4
                        : lowestPrice}
                    </TypographyV3>
                  </div>
                </div>
                <div className="flex flex-row items-center md:flex-col-reverse md:items-end md:gap-2 lg:gap-4 xl:flex-row xl:items-end">
                  <ButtonV3
                    variant={'outlined-light'}
                    size="medium"
                    className="hidden w-full justify-around text-nowrap font-bold hover:text-gray-400 md:flex lg:px-4 lg:py-2 xl:px-6 xl:py-3"
                    href={hrefExplore}
                    data-cy={dataCyExplore}
                    onClick={() =>
                      handleClick({
                        cta: 'explore',
                        location,
                        path: hrefExplore,
                        action: popAction,
                      })
                    }
                  >
                    {t('explore')}
                  </ButtonV3>

                  <ButtonV3
                    variant="unset"
                    size="medium"
                    className="w-full justify-around text-nowrap bg-sandstone-500 font-bold text-sandstone-200 hover:bg-gray-600 md:border md:border-sandstone-100 md:bg-sandstone-200 md:text-sandstone-500 hover:md:border-gray-400 hover:md:bg-gray-400 lg:px-4 lg:py-2 xl:px-6 xl:py-3"
                    href={hrefShop}
                    data-cy={dataCyShop}
                    onClick={() =>
                      handleClick({
                        cta: 'shop',
                        location,
                        path: hrefShop,
                        action: pdpAction,
                      })
                    }
                  >
                    {t('shop')}
                  </ButtonV3>
                </div>
              </div>
            </div>
          </Motion>
        ),
      )}
    </section>
  );
};
export default HomeRingModules;
