import useIsBFCM from '@/app/hooks/useIsBFCM';
import useIsHoliday2024 from '@/app/hooks/useIsHoliday2024';

import BlackFriday2024Hero from './_heros/BlackFriday2024Hero';
import Holiday2024Hero from './_heros/Holiday2024Hero';
import OR4Hero from './_heros/OR4Hero';

const HomeHeroGate = (): JSX.Element => {
  const bfcm2024Enabled = useIsBFCM();
  const holiday2024Enabled = useIsHoliday2024();

  if (holiday2024Enabled) {
    return <Holiday2024Hero />;
  }

  if (bfcm2024Enabled) {
    return <BlackFriday2024Hero />;
  }

  return <OR4Hero />;
};

export default HomeHeroGate;
